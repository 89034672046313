import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import {changePageNext} from "@/utils";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/user',
    name: 'User',
    meta: {requiresAuth: true},
    component: () => import('@/views/UserView.vue')
  },
  {
    path: '/odi',
    name: 'odi',
    component: () => import('@/views/other/ODIView.vue')
  },
  {
    path: '/sale/list/:markId?',
    name: 'saleList',
    meta: {requiresAuth: true},
    component: () => import('@/views/sale/SaleListView.vue')
  },
  {
    path: '/sale/:id',
    name: 'saleDetail',
    meta: {requiresAuth: true},
    component: () => import('@/views/sale/SaleDetailView.vue')
  },
  {
    path: '/area/list',
    name: 'areaList',
    component: () => import('@/views/area/AreaListView.vue')
  },
  {
    path: '/area/:cont/:country',
    name: 'areaDetail',
    meta: {parent: "/area/list"},
    component: () => import('@/views/area/AreaDetailView.vue')
  },
  {
    path: '/schedule/:serviceType/:type',
    name: 'scheduleDetail',
    component: () => import('@/views/schedule/ProfessionalScheduleView.vue')
  },
  {
    path: '/qa',
    name: 'Qa',
    component: () => import('@/views/qa/QaView.vue')
  },
  {
    path: '/news/list/:categoryId?',
    name: 'NewsList',
    component: () => import('@/views/news/NewsListView.vue')
  },
  {
    path: '/news/detail/:id?',
    name: 'NewsDetail',
    component: () => import('@/views/news/NewsDetailView.vue')
  },
  {
    path: '/risk/list',
    name: 'RiskList',
    component: () => import('@/views/risk/RiskListView.vue')
  },
  {
    path: '/risk/detail/:id?',
    name: 'RiskDetail',
    component: () => import('@/views/risk/RiskDetailView.vue')
  },
  {
    path: '/activity/list',
    name: 'ActivityList',
    component: () => import('@/views/activity/ActivityListView.vue')
  },
  {
    path: '/activity/detail/:id?',
    name: 'ActivityDetail',
    meta: {parent: "/activity/list"},
    component: () => import('@/views/activity/ActivityDetailView.vue')
  },
  {
    path: '/activityPlan/list',
    name: 'ActivityPlanList',
    component: () => import('@/views/activityPlan/ActivityPlanListView.vue')
  },
  {
    path: '/activityPlan/detail/:id?',
    name: 'ActivityPlanDetail',
    meta: {parent: "/activityPlan/list"},
    component: () => import('@/views/activityPlan/ActivityPlanDetailView.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: {requiresAuth: true},
    component: () => import('@/views/search/SearchListView.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/other/WelcomeView.vue')
  },
  {
    path: '/devAndReform',
    name: 'DevAndReform',
    component: () => import('@/views/other/DevAndReformView.vue')
  },
  {
    path: '/commerce',
    name: 'Commerce',
    component: () => import('@/views/other/CommerceView.vue')
  },
  {
    path: '/foreigner',
    name: 'Foreigner',
    component: () => import('@/views/other/ForeignerView.vue')
  },
  {
    path: '/overseas',
    name: 'Overseas',
    component: () => import('@/views/other/OverseasView.vue')
  },
  {
    path: '/apec',
    name: 'Apec',
    component: () => import('@/views/other/ApecView.vue')
  },
  {
    path: '/talent',
    name: 'Talent',
    component: () => import('@/views/other/TalentView.vue')
  },
  {
    path: '/madrid',
    name: 'MadridView',
    component: () => import('@/views/other/MadridView.vue')
  },
  {
    path: '/logOut',
    name: 'logOutView',
    component: () => import('@/views/LoginOutView.vue')
  },
  {
    path: '/service/:type?',
    name: 'ServiceView',
    component: () => import('@/views/other/ServiceView.vue')
  },
  {
    path: '/manager/schedule',
    name: 'ScheduleManager',
    meta: {requiresAuth: true},
    component: () => import('@/views/manager/schedule/ScheduleManagerView.vue')
  },
  { path: "/:pathMatch(.*)", redirect: "/", name: "notMatch", hidden: true },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('setReady', false)
  if(store.getters.token){
    if(store.getters.user) {
      next();
    } else {
      store.dispatch('user/GetInfo').then(() => {
        debugger
        next();
      },() => {
        debugger
        store.dispatch('user/LogOut').then(() => {
          if (to.matched.some(record => record.meta.requiresAuth)) {
            changePageNext(next, {path: "/login"}, to.path)
          } else {
            next();
          }
        })
      })
    }
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      changePageNext(next, {path: "/login"}, to.path)
    } else {
      next();
    }
  }
});

export default router
